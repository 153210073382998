import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body" }
const _hoisted_2 = {
  href: "#",
  class: "card-title fw-bolder text-muted text-hover-primary fs-4"
}
const _hoisted_3 = { class: "fw-bolder text-primary my-6" }
const _hoisted_4 = { class: "text-dark-75 fw-bold fs-5 m-0" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card bgi-no-repeat"]),
    style: _normalizeStyle([`background-image: url('media/svg/shapes/${_ctx.background}')`, {"background-position":"right top","background-size":"30% auto"}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.time), 1),
      _createElementVNode("p", _hoisted_4, [
        _createElementVNode("span", { innerHTML: _ctx.description }, null, 8, _hoisted_5)
      ])
    ])
  ], 6))
}