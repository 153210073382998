import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body my-3" }
const _hoisted_2 = { class: "py-1" }
const _hoisted_3 = { class: "text-dark fs-1 fw-bolder me-2" }
const _hoisted_4 = { class: "fw-bold text-muted fs-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[_ctx.widgetClasses, `bg-light-${_ctx.color}`], "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        href: "#",
        class: _normalizeClass([`text-${_ctx.color}`, "card-title fw-bolder fs-5 mb-3 d-block"])
      }, _toDisplayString(_ctx.description), 3),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.progress), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass([`bg-${_ctx.color}`, "progress h-7px bg-opacity-50 mt-7"])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([`bg-${_ctx.color}`, "progress-bar"]),
          role: "progressbar",
          style: _normalizeStyle(`width: ${_ctx.progress}`),
          "aria-valuenow": "50",
          "aria-valuemin": "0",
          "aria-valuemax": "100"
        }, null, 6)
      ], 2)
    ])
  ], 2))
}